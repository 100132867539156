.container {
  position: relative;
}

.flex {
  display: flex;
  align-items: center;
  //   width: 221px;
  background: #f9f2ff;
  border-radius: 19px;
}

.option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 33px;
  cursor: pointer;
  .text {
    position: relative;
  }
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #9e6fcd;
    z-index: 5;
    &:last-child {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }
  }
  &.inactive {
    p:last-child {
      opacity: 0;
    }
  }
}

.toggler {
  position: absolute;
  width: 127px;
  height: 89px;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, #a375d1 0%, #b67eee 100.02%);
  border-radius: 24px;
  transition: 0.2s;
  &.matic {
    left: 75%;
  }
  &.polygon {
    left: 25%;
  }
}
