.container {
  padding: 30px 38px;
  background: #ffffff;
  flex: 1;
  max-width: 614px;
  box-shadow: 0px 4px 4px rgba(196, 172, 219, 0.26),
    0px 4px 100px rgba(220, 189, 252, 0.35);
  border-radius: 23px;
  h2 {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #815ba8;
  }
  table {
    margin-top: 10px;
    th,
    td {
      padding: 10px 0;
    }
    th {
      text-align: left;
      padding-right: 10px;
    }
  }
}
