.container {
  margin: 23px 0 50px 0;
}

.innerContainer {
  display: flex;
  box-shadow: 0px 4px 4px rgba(196, 172, 219, 0.26),
    0px 4px 100px rgba(220, 189, 252, 0.35);
  border-radius: 23px;
  // overflow: hidden;
  height: 634px;
}

.contacts {
  padding: 43px 21px;
  flex: 1;
  max-width: 376px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
  }
  h2 {
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: #815ba8;
  }
  .edit {
    color: #815ba8;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    margin-top: 31px;
  }
}

.contact {
  margin-bottom: 12px;
  padding: 17px 22px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s;
  p {
    transition: 0.2s;
  }
  .nickname {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  .address {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  &.selected {
    background: rgba(231, 207, 255, 0.26);
    p {
      color: #8b58bd;
    }
  }
  input {
    background-color: none;
    border: 0;
    &.nickname {
      cursor: pointer;
      &::placeholder {
        color: #8b58bd;
      }
    }
    &:focus {
      outline: none;
      &::placeholder {
        color: #9f9f9f;
      }
    }
  }
  .addBtn {
    background-color: transparent;
    display: flex;
    align-items: center;
  }
  .newContact {
    cursor: auto;
  }
}

.trash path {
  fill: gray;
  &:hover {
    fill: #f24e1e;
  }
}

.sender {
  flex: 1;
  background-color: #815ba8;
  padding: 29px 47px;
  border-radius: 0 23px 23px 0;
  &.noOverflow {
    overflow: hidden;
  }
  .box {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
  }
}

.amount {
  padding: 0 62px;
  height: 158px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .input {
    display: flex;
    flex: 1;
    align-items: center;
    .symbol {
      align-self: center;
      transform: translateY(-3px);
    }
    input {
      flex: 1;
      font-weight: 400;
      font-size: 72px;
      line-height: 86px;
      //   height: 5px;
      max-width: 250px;
      color: #9e6fcd;
      font-family: "Bebas Neue";
      margin-left: 8px;
      border: none;
    }
  }
  .balance {
    font-weight: 400;
    font-size: 14.53px;
    line-height: 17px;
    color: #a3a3a3;
    margin-top: 5px;
    display: flex;
    align-items: center;
    .sym {
      transform: translateY(2px);
      margin-left: 5px;
      path {
        fill: #a3a3a3;
      }
    }
  }
  .status {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      svg {
        transform: translateY(1px);
        margin-right: 1px;
      }
      font-weight: 700;
      font-size: 17.6923px;
      line-height: 21px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      text-align: center;
    }
    .progress {
      height: 22px;
      width: 100%;
      background: #f9f2ff;
      border-radius: 8px;
      overflow: hidden;
      .bar {
        border-radius: 8px;
        height: 100%;
        transition: 0.5s;
      }
    }
    &.remaining {
      .sym path {
        fill: #815ba8;
      }
      .text {
        color: #ab82d4;
      }
      .bar {
        background: linear-gradient(90deg, #a375d1 0%, #b67eee 100.02%);
      }
    }
    &.exceeding {
      .sym path {
        fill: #f24e1e;
      }
      .text {
        color: #f24e1e;
      }
      .bar {
        background: #f24e1e;
      }
    }
    &.ready {
      .sym path {
        fill: #31c21b;
      }
      .text {
        color: #31c21b;
      }
      .bar {
        background: #31c21b;
      }
    }
  }
}

.split {
  padding: 30px 62px;
  background: #ab82d4;
  .selectedContact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #f5f5f5;
    margin-bottom: 20px;
    .nickname {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
    .address {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    .splitAmount {
      .sym {
        path {
          fill: white;
        }
      }
      input {
        width: 64px;
        height: 30px;
        font-size: 25px;
        line-height: 30px;
        font-family: "Bebas Neue";
        background-color: transparent;
        border: none;
        color: #fff;
      }
      border-bottom: 1px solid white;
    }
  }
}

.menu {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coinSelect {
  position: relative;
  .clickable {
    background-color: #fff;
    border-radius: 36px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .arrow {
      margin-left: 20px;
      margin-right: 6px;
    }
  }
  .drop {
    position: absolute;
    top: 100%;
    left: -5px;
    width: 250px;
    padding: 5px;
    background-color: #fff;
    margin-top: 5px;
    z-index: 5;
    box-shadow: 0 0 9px 4px #00000021;
    border-radius: 7px;
    .token {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        background-color: #f5f5f5;
      }
      .text {
        margin-left: 10px;
        p {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: #a3a3a3;
        }
        h3 {
          font-weight: 600;
          margin-bottom: 2px;
        }
      }
    }
  }
}

.send {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 0 25px;
  background-color: #fff;
  border-radius: 20px;
  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #9364c2;
    z-index: 10;
  }
  .icon {
    width: 38px;
    height: 38px;
    display: grid;
    place-items: center;
    margin-left: 13px;
    position: relative;
    &.completed {
      svg {
        opacity: 0;
      }
    }
    svg {
      z-index: 10;
    }
    .sendBg {
      background: #f9f2ff;
      border-radius: 566.597px;
      position: absolute;
      z-index: 5;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: 0.2s;
      // transform: scale(15);
      &.pending {
        animation: pulsateScale 1s infinite 0.2s;
        transform: scale(15);
      }
      &.completed {
        transform: scale(100);
        background-color: #9364c2;
      }
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 3;
  opacity: 0.5;
}

@keyframes pulsateScale {
  0% {
    transform: scale(16.5);
  }
  80% {
    transform: scale(15);
  }
  100% {
    transform: scale(16.5);
  }
}
