.section {
  background: #fff;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}

.logo {
  display: flex;
  align-items: center;
  .text {
    margin-left: 18px;
    h1 {
      font-weight: 800;
      font-size: 18.1888px;
      line-height: 22px;
      color: #4b4b4b;
      margin-bottom: 1px;
    }
    p {
      font-size: 10.066px;
      line-height: 12px;
      color: #4b4b4b;
    }
  }
}

.connect {
  background: #9e6fcd;
  border-radius: 15px;
  padding: 16px 43px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.connected {
  display: flex;
  align-items: center;
  .dot {
    width: 10px;
    height: 10px;
    background: rgb(58, 216, 58);
    border-radius: 500px;
    margin-right: 8px;
  }
}
