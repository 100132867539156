@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Syne", Tahoma, Geneva, Verdana, sans-serif;
  background: #fefcff;
}

button {
  border: 0;
  font-family: "Syne", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}

input {
  font-family: "Syne", Tahoma, Geneva, Verdana, sans-serif;
  &:hover,
  &:focus-visible {
    outline: 0;
  }
}

.number {
  font-family: "Bebas Neue", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  width: 100%;
  overflow-x: hidden;
}
