.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.logo {
  width: 138px;
  height: 138px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 99px;
  svg {
    z-index: 1;
  }
}

.text {
  font-weight: 700;
  font-size: 28.169px;
  line-height: 34px;
  width: 299px;
  margin-left: 78px;
  color: #ffffff;
}

.ripple {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 0;
  border-radius: 99px;
  pointer-events: none;
}

.r1 {
  animation: pulsateScaleFade 1.5s infinite;
}
.r2 {
  animation: pulsateScaleFade 1.5s infinite 0.3s;
}

@keyframes pulsateScaleFade {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}
